<template>
  <div class="page-container">
    <header v-if="promotion.settings.header.show === true" class="header" :style="getStyle('header')">
      <h1 :style="getStyle('header')">
        {{ promotion.name }}
      </h1>
    </header>
    <main class="main-content" :style="getStyle('content')">
      <validation-observer #default="{ handleSubmit }"
                           ref="refFormDocumentObserver"
      >
        <b-form @submit.prevent="handleSubmit(validateDocument)">
          <b-row class="justify-content-center align-content-center align-items-center">
            <b-col sm="3" md="3">
              <i2-form-input
                v-model="model.cpf"
                label="Documento (CPF ou CNPJ)"
                name="Documento"
                rules="required|cpfCnpj"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="Digite seu CPF / CNPJ"
                @input="resetForm"
              />
            </b-col>
            <b-col sm="3" md="3">
              <b-button class="btn-icon" :style="getStyle('button')" @click="validateDocument">
                <feather-icon icon="CheckCircleIcon" style="font-size: 1.7em; padding-right:3px;"/>
                Verificar Cadastro
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div v-if="isNewParticipant">
        <participant-new-form
          :document="model.cpf"
          :headers="getConfiguration()"
          :button-style="getStyle('button')"
          @onSave="validateDocument"
        />
      </div>
      <i2-divider/>
      <div v-if="isShowCodesContainer">
        <b-row>
          <b-col class="text-center">
            <h3>Digite abaixo os códigos de seus cupons.</h3>
          </b-col>
        </b-row>
        <validation-observer #default="{ handleSubmit }"
                             ref="refFormAddCuponObserver"
        >
          <b-form @submit.prevent="handleSubmit(addCoupon)">
            <b-row class="justify-content-center align-content-center align-items-center">
              <b-col md="3" sm="3">
                <i2-form-input
                  v-model="coupon.code"
                  label="Código"
                  name="coupon_code"
                  rules="required"
                  placeholder="Digite o código"
                />
              </b-col>
              <b-col md="3" sm="3">
                <b-button class="btn-icon" :style="getStyle('button')" @click="addCoupon">
                  <feather-icon icon="PlusIcon" style="font-size: 1.7em; padding-right:3px;"/>
                  Adicionar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="justify-content-center align-content-center align-items-center">
            <b-col md="8" sm="8">
              <b-table
                :items="itemsCode"
                :fields="fields"
                show-empty
                small
              >
                <template #empty class="text-center">
                  <b-row class="text-center p-2" >
                    <b-col>
                      <b-img :src="require('@/assets/images/icons/empty.svg')" height="30" />
                      Nenhum cupom lançado
                    </b-col>
                  </b-row>
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="data.item.variant" class="d-block">
                    {{ data.item.status }}
                  </b-badge>
                </template>

                <template #cell(action)="data">
                  <i2-icon-button
                    variant="outline-danger"
                    icon="Trash2Icon"
                    @click="removeCoupomCode(data.item)"
                  />

                </template>
              </b-table>

            </b-col>
          </b-row>
          <b-row class="justify-content-center align-content-center align-items-center">
            <b-col md="8" sm="8" >
              <b-button block :disabled="itemsCode.length === 0" class="btn-icon" :style="{...getStyle('button'), minHeight: '70px'}" @click="getMyNumbers">
                Gerar meus números
              </b-button>
            </b-col>
          </b-row>

        </validation-observer>
      </div>

    </main>
    <footer v-if="promotion.settings.footer.show === true" class="footer" :style="getStyle('footer')">
      <p>
        <a target="_blank" href="https://globalpromocoes.com/">
          Plataforma Global de Promoções
        </a> - Todos Direitos Reservados {{ currentDate.getFullYear() }} &copy;
      </p>
    </footer>
  </div>
</template>

<script>
import I2Divider from '@/components/i2/content/i2Divider.vue'
import { ValidationObserver } from 'vee-validate'
import ParticipantNewForm from '@/views/public/ParticipantNewForm.vue'

export default {
  name: 'Participants',
  components: { ParticipantNewForm, I2Divider, ValidationObserver },
  data() {
    return {
      currentDate: new Date(),
      isNewParticipant: false,
      isShowCodesContainer: false,
      personDocument: '',
      promotion: {
        name: 'Carregando...',
        settings: {
          header: {
            background_color: '',
            text_color: '',
            show: true,
          },
          footer: {
            background_color: '',
            text_color: '',
            show: true,
          },
          button: {
            background_color: '',
            text_color: '',
          },
        },
      },
      promotionUuid: '',
      token: '',
      queryData: null,
      model: {
        cpf: '',
        document: '',
        name: '',
        phone: '',
        email: '',
        birthdate: '',
        city: '',
        state: '',
        neighborhood: '',
        address: '',
        complement: '',
        zip_code: '',
        address_number: '',
      },
      itemsCode: [],
      coupon: {
        code: '',
      },
      fields: [
        {
          key: 'code',
          label: 'Código',
          class: 'text-center',
        },
        {
          key: 'status',
          label: 'Situação',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'text-center',
        },
      ],
    }
  },
  beforeCreate() {
    localStorage.clear()
  },
  mounted() {
    const getQueryParam = param => {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    }
    this.queryData = JSON.parse(atob(getQueryParam('d')))
    this.coupon.code = getQueryParam('code')
    this.getPromotion()
  },
  methods: {
    resetForm() {
      this.isShowCodesContainer = false
      this.isNewParticipant = false
      this.model = {
        cpf: this.model.cpf,
        document: '',
        name: '',
        phone: '',
        email: '',
        birthdate: '',
        city: '',
        state: '',
        neighborhood: '',
        address: '',
        complement: '',
        zip_code: '',
        address_number: '',
      }
    },
    async getPromotion() {
      this.promotion = await this.$http.get(`/promotion/${this.queryData.uuid}/code`, this.getConfiguration())
      if (this.promotion.settings.footer.show === null) {
        this.promotion.settings.footer.show = true
      }
      if (this.promotion.settings.header.show === null) {
        this.promotion.settings.header.show = true
      }
    },
    async validateDocument() {
      this.$refs.refFormDocumentObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const documentSearch = this.model.cpf.replace(/\D/g, '')
        const existParticipant = await this.$http.get(`/person/${documentSearch}`, this.getConfiguration())
        this.isNewParticipant = !existParticipant
        this.isShowCodesContainer = existParticipant
        this.personDocument = this.model.cpf
      })
    },
    async addCoupon() {
      this.$refs.refFormAddCuponObserver.validate().then(async success => {
        if (!success) {
          return
        }
        const response = await this.$http.get(`/promotion/${this.queryData.uuid}/code/${this.coupon.code}`, this.getConfiguration())
        let statusMessage = 'Válido'
        let variant = 'success'
        if (response === 'not_found') {
          this.$notify.error(`Cupom ${this.coupon.code.toUpperCase()} inválido.`)
          return
        }

        if (response === 'in_use') {
          this.$notify.warning(`Cupom ${this.coupon.code.toUpperCase()} já utilizado.`)
          return
        }

        const item = {
          code: this.coupon.code.toUpperCase(),
          status: statusMessage,
          variant,
        }
        this.itemsCode.push(item)
        this.coupon.code = ''
        this.$refs.refFormAddCuponObserver.reset()
      })
    },
    async getMyNumbers() {
      const data = {
        promotion_uuid: this.queryData.uuid,
        codes: this.itemsCode,
      }
      const document = this.model.cpf.replace(/\D/g, '')
      const response = await this.$http.post(`/person/${document}/codes`, data, this.getConfiguration())
      if (response && (response.error || response.error_status)) {
        this.$notify.error('Não foi possível gerar os números.')
        return
      }
      this.$notify.success('Números gerados com sucesso.')
      this.itemsCode = []
    },
    removeCoupomCode(item) {
      const items = []
      this.itemsCode.forEach(codeItem => {
        if (item.code !== codeItem.code) {
          items.push(codeItem)
        }
      })
      this.itemsCode = items
    },
    getConfiguration() {
      return {
        headers: {
          Authorization: `Bearer ${this.queryData.t}`,
        },
      }
    },
    getStyle(where) {
      if (!this.promotion.settings) {
        return {}
      }
      if (where === 'button' && this.promotion.settings[where]) {
        return {
          backgroundColor: `${this.promotion.settings[where].background_color} !important`,
          borderColor: `${this.promotion.settings[where].background_color} !important`,
          color: `${this.promotion.settings[where].text_color} !important`,
        }
      }

      if (this.promotion.settings[where]) {
        return {
          backgroundColor: this.promotion.settings[where].background_color,
          color: `${this.promotion.settings[where].text_color} !important`,
        }
      }
      return {}
    },
  },
}
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page-container {
    padding: 10px;
  }
}

.header, .footer {
  width: 100%;
  text-align: center;
}

.header {
  background-color: #F4F8FD;
  padding: 15px 0;
}

.main-content {
  flex: 1;
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
}

.footer {
  background-color: #F4F8FD;
  padding: 15px 0;
}
</style>
